import { render, staticRenderFns } from "./action-card.vue?vue&type=template&id=7fa89d96&"
import script from "./action-card.vue?vue&type=script&lang=js&"
export * from "./action-card.vue?vue&type=script&lang=js&"
import style0 from "./action-card.vue?vue&type=style&index=0&id=7fa89d96&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ActionCardBackground: require('/opt/build/repo/components/action-card-background.vue').default,ActionDescription: require('/opt/build/repo/components/action-description.vue').default,ActionRecentCompletions: require('/opt/build/repo/components/action-recent-completions.vue').default,AppConcept: require('/opt/build/repo/components/app-concept.vue').default,InterestsTextDisplay: require('/opt/build/repo/components/interests-text-display.vue').default,ActionImportanceEffort: require('/opt/build/repo/components/action-importance-effort.vue').default,Value: require('/opt/build/repo/components/value.vue').default,Icon: require('/opt/build/repo/components/icon.vue').default,EffectApplyButton: require('/opt/build/repo/components/effect-apply-button.vue').default,EffectsButton: require('/opt/build/repo/components/effects-button.vue').default})
