
import regl from 'regl';

import { Action } from '../api/src/models/Action';
import { shaders } from '../lib/action-card-background';

const WIDTH = 445;
const HEIGHT = 445;
const SHADER = 'starNest2';

export default {
  name: 'ActionCardBackground',
  props: {
    actionId: {
      type: String,
      default: '',
    },
    colors: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      width: WIDTH,
      height: HEIGHT,
    };
  },
  computed: {
    action() {
      return this.$store.getters.getActionById(this.actionId);
    },
    hasAction() {
      return Boolean(this.action);
    },
    actionStatus() {
      return this.hasAction ? this.action.status : Action.Status.enum.Todo;
    },
    rand() {
      return this.$store.getters.getRandom(this.actionId);
    },
    color() {
      const hex = this.colors.length > 0 ? this.colors[0].replace('#', '') : 'FFFFFF';
      return [
        parseInt(hex.substring(0, 2), 16) / 255,
        parseInt(hex.substring(2, 4), 16) / 255,
        parseInt(hex.substring(4, 6), 16) / 255,
      ];
    },
    importanceMultiplier() {
      let m = 0;

      if (this.hasAction && this.action.importance) {
        const min = 0;
        const max = 1;
        m = min + ((max - min) * (this.action.importance / Action.EffortImportance.ImportanceMaxValue));
      }

      return m;
    },
  },
  created() {
    this._reglInstance = undefined;
  },
  mounted() {
    this.$nextTick(() => {
      this.initShader();
    });
  },
  beforeDestroy() {
    this.removeShader();
  },
  methods: {
    initShader() {
      const glCanvas = this.$refs['gl-canvas'];

      if (this._reglInstance || !glCanvas) {
        return;
      }

      const fragmentShader = shaders[SHADER].frag;
      const start = `// #version 300 es
      precision mediump float;

      uniform vec2 iResolution;
      uniform vec4 iMouse;
      uniform float iTime;
      uniform float rand;
      uniform float importanceMultiplier;
      uniform float completeness;
      `;

      const end = `
      void main() {
        mainImage(gl_FragColor.rgba, gl_FragCoord.xy);
      }
      `;

      this._reglInstance = regl(glCanvas);
      const draw = this._reglInstance({

        // Shaders in regl are just strings.  You can use glslify or whatever you want
        // to define them.  No need to manually create shader objects.
        frag: `${start}${fragmentShader}${end}`,

        vert: `
        precision mediump float;
        attribute vec2 position;
        varying vec2 uv;
        void main () {
          uv = position;
          gl_Position = vec4(2.0 * position - 1.0, 0, 1);
        }`,

        // This tells regl the number of vertices to draw in this command
        count: 3,

        attributes: {
          position: [
            -2, 0,
            0, -2,
            2, 2]
        },

        uniforms: {
          iResolution: [WIDTH, HEIGHT],
          iMouse: [0, 0, 0, 0],
          iTime: ({ tick }) => 0.01 * tick,
          rand: this._reglInstance.prop('rand'),
          importanceMultiplier: this._reglInstance.prop('importanceMultiplier'),
          completeness: this._reglInstance.prop('completeness'),
        },
      });

      this._reglInstance.frame(() => {
        if (!this.isVisible) {
          return;
        }

        // clear contents of the drawing buffer
        this._reglInstance.clear({
          color: [0, 0, 0, 0],
          depth: 1
        })

        draw({
          rand: this.rand,
          importanceMultiplier: this.importanceMultiplier,
          completeness: this.actionStatus === Action.Status.enum.Todo
            ? 0.0
            : 1.0,
        });
      });
    },
    removeShader() {
      if (this._reglInstance) {
        this._reglInstance.destroy();
      }
    },
  },
};
