
import { mapGetters } from 'vuex';

import { Action } from '../api/src/models/Action';
import { Effect } from '../api/src/models/effect';

import actionMixin from '../mixins/action';
import uiMixin from '../mixins/ui';

let Visibility;

export default {
  name: 'ActionCard',
  mixins: [
    actionMixin,
    uiMixin,
  ],
  props: {
    actionId: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultEffectName: Effect.EffectName.enum.Completion,
      isPageVisible: false,
      visibilityListener: undefined,
      importanceDescriptorMap: Action.EffortImportance.ImportanceDescriptorMap,
      effortDescriptorMap: Action.EffortImportance.EffortDescriptorMap,
      autostopMs: undefined,
    };
  },
  computed: {
    ...mapGetters('actionFocus', {
      actionFocusMsSinceStart: 'msSinceStart',
    }),
    isBackgroundVisible() {
      return this.isPageVisible && this.isVisible && this.$store.state.ui.isAdvancedUIEnabled;
    },
    descriptionProps() {
      const props = { is: 'span' };

      if (this.action.link) {
        props.is = 'a';
        props.target = '_blank';
        props.href = this.action.link;
      }

      return props;
    },
    optionsMenuItems() {
      const items = [];

      if (!this.isInFocus) {
        const canBeEdited = this.isRepeating || !this.isComplete;

        if (this.action.link) {
          items.push({
            label: 'Copy Link',
            icon: ['fal', 'copy'],
            command: () => {
              this.uiCopyToClipboard(this.action.link);
            },
          });
        }

        if (canBeEdited) {
          items.push({
            label: 'Edit',
            icon: ['fal', 'pencil'],
            // This is really only needed for a cypress test to assert non-existence :P
            class: 'action-card-options-edit',
            command: () => {
              this.doEdit();
            },
          });
        }

        items.push({
          label: 'Delete',
          icon: ['fal', 'xmark'],
          command: () => {
            this.doDelete();
          },
        });
      }

      return items;
    },
    autostopOptions() {
      return [
        {
          label: 'at 5:00',
          value: 300000,
          isDisabled: this.actionFocusMsSinceStart >= 300000,
        },
        {
          label: 'at 10:00',
          value: 600000,
          isDisabled: this.actionFocusMsSinceStart >= 600000,
        },
        {
          label: 'at 15:00',
          value: 900000,
          isDisabled: this.actionFocusMsSinceStart >= 900000,
        },
        {
          label: 'at 20:00',
          value: 1200000,
          isDisabled: this.actionFocusMsSinceStart >= 1200000,
        },
      ];
    },
    effortImportanceDescriptor() {
      const text = [];
      text.push(this.effortDescriptorMap[this.action.effort]);
      text.push(this.importanceDescriptorMap[this.action.importance]);
      return text.join(', ');
    },
  },
  watch: {
    actionFocusMsSinceStart() {
      if (this.actionFocusMsSinceStart && this.autostopMs && this.actionFocusMsSinceStart >= this.autostopMs) {
        this.$store.commit('actionFocus/isStopping', true);
      }
    },
  },
  async mounted() {
    if (!Visibility) {
      Visibility = await import('visibilityjs');
    }

    if (Visibility) {
      this.isPageVisible = !Visibility.hidden();

      this.visibilityListener = Visibility.change(() => {
        this.isPageVisible = !Visibility.hidden();
      });
    }

    this.$nextTick(() => {
      if (this.$refs.defaultEffectButton && this.isCompletable) {
        this.$refs.defaultEffectButton.focus();
      }
    });
  },
  destroyed() {
    if (Visibility && this.visibilityListener) {
      Visibility.unbind(this.visibilityListener);
    }
  },
  methods: {
    doEdit() {
      if (this.action) {
        this.uiShowActionEdit(this.action.id);
      }
    },
    async doDelete() {
      let isDeleting = false;

      if (this.action) {
        isDeleting = await this.uiDeleteAction(this.action.id);
      }

      if (isDeleting) {
        this.$emit('delete');
      }
    },
  },
};
